@import "font/css/all.min.css";
@import "react-pdf/dist/esm/Page/AnnotationLayer.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: hidden;
}

body {
  margin: 0;
  font-family: "Noto Sans JP", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to bottom, #1b75b1 0%, #ffffff 100%);
  background-size: 100% 200px;
  background-repeat: no-repeat;
  overflow-y: hidden;
}

.footer-bar {
  position: absolute;
  bottom: 30px;
  width: 1280px;
}

.page-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-back {
  position: fixed;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.input-login {
  @apply h-[45px] border-cblue border-2 rounded px-7;
}

.home-btn {
  background-image: url("../public/img/home_btn.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: bold;
}
.home-btn1 {
  background-image: url("../public/img/home_btn1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  font-weight: bold;
}


.logo {
  background-image: url("../public/logo192.png");
  background-color: white;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 100%;
}

.lesson-btn {
  background-image: url("../public/img/problem_btn.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.list-btn {
  background-image: url("../public/img/list_btn.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.pwa {
  animation: moveUpDown 1.5s infinite;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

.btn-add {
  @apply w-[120px] h-[30px] rounded-lg shadow-md text-base text-white bg-cblue hover:bg-blue-500 font-bold;
}

.btn-top {
  @apply w-[85px] h-[45px] shadow-lg bg-cblue text-white hover:bg-blue-500 text-sm grid justify-items-center content-center;
}

.btn-bottom {
  @apply w-[75px] h-[45px] shadow-lg bg-cblue text-white hover:bg-blue-500 text-[11px] grid justify-items-center content-center;
}

.active-btn-bottom {
  @apply w-[75px] h-[45px] shadow-lg text-white bg-gray-700 text-[11px] grid justify-items-center content-center;
}

.active-btn-top {
  @apply w-[85px] h-[45px] shadow-lg text-white bg-gray-700 text-sm grid justify-items-center content-center;
}

.after\:ml-1::after {
  margin-left: 0px !important;
}

.before\:mr-1::before {
  margin-right: 0px !important;
}

.custom-zoom [data-rmiz-modal-overlay="hidden"] {
  background-color: rgba(0, 0, 0, 0.7);
}
.custom-zoom [data-rmiz-modal-overlay="visible"] {
  background-color: rgba(0, 0, 0, 0.7);
}
.custom-zoom [data-rmiz-modal-content] img {
  @apply rounded-2xl !transition !duration-300 !ease-in-out;
}
.custom-zoom [data-rmiz-btn-unzoom] {
  display: none;
}
.my-div {
  touch-action: none;
}
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.contextmenu-item {
  color: red;
  padding-top: 5px;
  padding-left: 15px;
  cursor: pointer;
}

.contextmenu {
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  width: 120px !important;
  background-color: azure;
  border-radius: 5px;
}
.contextmenu__item {
  color: red;
  padding: 0px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 15px !important;
}

@font-face {
  font-family: "KleeOne";
  src: url("../public/font/KleeOne-Regular.ttf") format("truetype");
  /* Add more font properties if needed */
}

.title {
  background-image: url("../public/img/title.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "KleeOne", "Klee One";
  color: rgb(23, 58, 100);
  font-weight: bold;
}

.title_2 {
  background-image: url("../public/img/title_2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  font-family: "KleeOne", "Klee One";
  font-weight: bold;
  color: rgb(23, 58, 100);
}

.pl-7 {
  padding-left: 0.5rem;
}

.pl-9 {
  padding-left: 1.5rem;
}

.pt-8 {
  padding-top: 1.7rem;
}
